.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@font-face {
    font-family: bologna;
    src: url("fonts/bologna/bologna-regular.otf");
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        /*animation: App-logo-float infinite 3s ease-in-out;*/
    }
}

.btn-link {
    color: #fdd9c3;
}

.btn-link:hover {
    color: #fdd9c3;
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px)
    }
    100% {
        transform: translateY(0px)
    }
}

.avatar-circle {
    width: 30px;
    height: 30px;
    border-radius: 15px;
}

#language-picker {
    background-color: unset!important;
    border: unset!important;
}

#language-picker:after {
    display: none;
}

.language-picker {
    background-color: unset!important;
    border: unset!important;
    width: 100%;
}

.btn:focus,.btn:active,.btn:focus-visible{
    outline: none !important;
    box-shadow: none !important;
}

.language-picker:hover {
    text-decoration-color: #fdd9c3 !important;
    text-decoration: underline !important;
}

.language-picker:after {
    display: none;
}

.table-hover tbody tr td, .table-hover tbody tr th .catalog-tr {
    background-color: black;
    opacity: 0.5;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th .catalog-tr {
    background-color: black;
    color: #fdd9c3;
    opacity: 1;
}

.tracklist-hover {
    opacity: 0.6;
}

.tracklist-nohover {
    opacity: 0.6;
}

.tracklist-hover:hover {
    color: #fdd9c3;
    opacity: 1;
}

.col {
    padding-right: 1vw;
    padding-left: 1vw;
}

.modal-content {
    background-color: black;
}

.modal-title {
    font-family: bologna, sans-serif;
    color: #fdd9c3;
}

.catalog-text {
    font-size: 1.4vw;
}

.catalog-col {
    margin-top: 1vw !important;
}

.language-picker-text {
    font-size: 1.1rem;
    white-space: nowrap;
}

.language-picker-text:hover {
    text-decoration: underline;
}

.footer-size {
    font-size: 1em;
}

.counter-text {
    font-size: 3.9vw;
}

.grants-headline {
    font-size: 1.8em;
}

.counter-text-holder {
}

.logo {
    width: 80%;
}

.footer-row {
    margin-top: 50vw
}

.fullscreen-image-container {
    width: 100vw;
    position: fixed; /* Stay in place */
    display: revert;
    z-index: 100001; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.modal-fullscreen {
    max-width: 95%;
}

.album-cover-tilt-container {
    width: 20vw;
    height: 20vw;
}

.album-cover-tilt-container {
    width: 20vw;
}

.album-cover-title {
    font-size: 1.3vw;
}

.album-cover-text {
    font-size: 1vw;
    opacity: 1;
}

.modal-tracklist-track-label {
    font-size: 1.5vw;
}

.modal-tracklist-track-container {

    vertical-align: middle;
    line-height: 2vw;
}

.modal-fullscreen-backdrop {
    opacity: 1!important;
    background-color: black;
}

.close-x {
    font-size: 2em;
}

.album-list-title {
    margin-right: 25vw;
}

.counter-help-text {
    font-size: 1.5vw;
}

a.link {
    text-decoration-color: #fdd9c3 !important;
    color: #fdd9c3 ;
    text-decoration: underline ;
}

a.link:hover {
    text-decoration: unset !important;
}


.faq-question {
    font-size: 2.25em;
    padding-top: 1vw;
}

.faq-answer {
    opacity: 0.6;
    font-size: 1.5em;
    padding-top: 1vw;
    white-space: pre-wrap;
}

.faq-line {

    padding-bottom: 1vw;
}

div.counter-text {
    min-width: 1vw;
}

.contact-form-submit {
    background-color: #fdd9c3;
    color: black;

    border: unset !important;
    min-width: 20%;

    outline: none !important;
    box-shadow: none !important;
}

.contact-form-submit:hover {
    text-decoration-color: #fdd9c3 !important;
    background-color: white;
    color: black;

}

.contact-form-label {
    font-size: 1.3em;
}

.popover-style-container {
    font-family: bologna;
    background-color: #fdd9c3;
    color: black;
}

.popover-style:hover {
    background-color: #efcdb8;

}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus .btn-link.focus .btn-link:focus .btn-link:active .btn:active .btn-link.active {
    outline: none!important;
    box-shadow: none!important;
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none;
}

.grants-grid-wrapper {
    display: grid;
    grid-template-rows: auto;
    gap: 1em;
    grid-template-columns: auto 20%;
}

.grants-grid-container-two {
    grid-column: 2;
}

.grants-reason {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1.5em;
    opacity: 0.88;
    grid-column: 1/-1;
    line-height: 1.3em ;
}

.grants-sum {
    text-align: right;
    width: min-content;
    font-size: 1.05em;
    white-space: nowrap;
}

.grants-sum-container {
    text-align: right;
    font-size: 1.5em;
}

.grants-date {
    padding-left: 0.5em;
}

.line-under-counter {
    margin-top: 1.8rem;
}

.counter-help-text-container {
    margin-left: 1.4em;
}

.contributors {
    font-size: 1.5rem;
    padding-top: 1vw;
    opacity: 0.9;
}

.contributors-list {
    padding-top: 1rem;
}
ul.contributors-list {
    line-height: 2.1rem;
}

@media only screen and (max-width: 1200px) {
    .language-picker-text {
        font-size: 1em
    }
}

@media only screen and (max-width: 900px) {
    .language-picker-text {
        font-size: 0.8em
    }
}

@media only screen and (max-width: 1600px) {
    .logo {
        width: 100%
    }
}


@media only screen and (max-width: 767px) {
    .catalog-text {
        font-size: 5vw;
    }

    .catalog-col {
        margin-top: unset;
    }

    .language-picker-text {
        font-size: 1em
    }

    .footer-size {
        font-size: 1em;
    }

    .counter-text {
        font-size: 8vw;
    }

    .counter-text-holder {
        text-align: center !important;
    }

    .logo {
        width: 100%
    }

    .row {
        margin-left: unset;
        margin-right: unset;
    }

    hr {
        margin-bottom: 20px !important;
    }

    .footer-row {
        margin-top: 5vw
    }

    .contract-image {
        margin-bottom: 3vw;
    }

    .album-cover-tilt-container {
        width: 90%;
        height: 90%;
    }

    .album-cover-tilt-container {
        width: unset;
        margin-left: unset;
    }

    .album-cover-title {
        font-size: 1.3em;
    }

    .album-cover-text {
        font-size: 1.2em;
        margin-left: 1vw;
        padding-top: 3vw
    }

    .album-cover-col {
        padding-right: 0;
        padding-left: 0;
    }


    .album-list-title {
        margin-right: unset;
    }

    .counter-col {
        max-width: unset;
        margin: auto
    }

    .counter-row {
        margin-left: -4vw;
    }

    .counter-help-text {
        font-size: 3.2vw;
    }

    .close-x {
        font-size: 2em;
    }

    .album-compensate-tracklist-capslock {
        font-size: 1em;
    }

    .faq-question {
        font-size: 1.7em;
        padding-top: 3vw;
        padding-bottom: 3vw;
    }

    .faq-answer {
        opacity: 0.6;
        font-size: 1.3em;
        padding-top: 1vw;
        padding-bottom: 3vw;
        line-height: 1.2em;
    }

    .grants-grid-wrapper {
        grid-template-columns: 1fr;
    }

    .grants-grid-container-two {
        grid-column: 1;
    }

    .grants-reason {
        padding-left: 0;
        grid-column: 1/-1;
        opacity: 0.7;
    }

    .grants-body-container {
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .grants-headline-container {
    }

    .grants-sum-container {
        width: auto;
    }

    .grants-sum {
        text-align: right;
        margin-left: auto;
        font-size: 1em;
        width: auto;
    }

    .grants-sum::before { content: '\A'; }

    .grants-date {

    }

    .line-under-counter {
        margin-top: 1.2rem;
    }

    .counter-help-text-container {
        text-align: center;
        margin-left: 1.6vw;
    }

    .contributors {

        font-size: 1.5em;
        padding-top: 3vw;
        padding-bottom: 3vw;


    }
}
